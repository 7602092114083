import React, { useEffect, useState } from "react"
import SEO from "../../components/seo"
import PageHeader from "../../components/PageHeader"
import headerImage from "../../images/gallery/1.jpg"
import styled from "styled-components"
import { GlobalContainer, GlobalSection } from "../../components/GlobalStyle"
import { Link } from "gatsby"
import image from "../../images/gallery/18.jpg"
import { useTranslation } from 'react-i18next';
import i18next from "i18next"

const StyledContainer = styled(GlobalContainer)`
  max-width: 1040px;
  margin-bottom: 200px;
`

const StyledBlogPosts = styled.div`
  position: relative;
  top: -200px;
`

const StyledBlogPost = styled(Link)`
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 100px;
  img {
    width: 100%;
    transition: 0.3s;
  }
  &:hover {
    img {
      transform: scale(1.1) rotate(-3deg);
    }
  }
`
const StyledBlogPostImage = styled.div`
  overflow: hidden;
`

const StyledBlogPostText = styled.div`
  text-align: left;
  color: #000;
  padding: 20px;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  margin: 20px;
  h3 {
    margin-bottom: 20px;
    font-size: 30px;
  }
  p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 20px;
  }
  a {
    color: var(--main);
    text-transform: uppercase;
    font-weight: bold;
  }
`

const Index = ({
  data: {
    allMarkdownRemark: { nodes },
  },
}) => {
	const { t,i18n } = useTranslation();
  const currentLocale = i18n.language
  const [posts, setPosts] = useState([])
  
  useEffect(() => {
    const filteredPosts = nodes
      .filter(
        edge =>
          !!edge.frontmatter.date && edge.frontmatter.locale == currentLocale
      )
      .map(edge => edge)
    setPosts(filteredPosts)
  }, [currentLocale])

  return (
    <>
      <SEO title="Blog" description="" />
      <PageHeader image={headerImage} gallery>
        <h1
          className="gallery-header"
          data-sal="slide-up"
          data-sal-delay="400"
          data-sal-duration="400"
        >
          Blog
        </h1>
      </PageHeader>
      <GlobalSection bg={"#eee"}>
        <StyledContainer>
          <StyledBlogPosts>
            {posts.map(post => (
              <StyledBlogPost to={`/blog${post.frontmatter.slug}`}>
                {/*fixed image, change to dynamic when more posts provided*/}
                <StyledBlogPostImage>
                  <img src={image} alt="blog cover" />
                </StyledBlogPostImage>
                <StyledBlogPostText>
                  <h3>{post.frontmatter.title}</h3>
                  <p>{post.frontmatter.description}</p>
                  <Link to={`/blog${post.frontmatter.slug}`}>
									{t('common.readMore')}
                  </Link>
                </StyledBlogPostText>
              </StyledBlogPost>
            ))}
          </StyledBlogPosts>
        </StyledContainer>
      </GlobalSection>
    </>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date }) {
      nodes {
        frontmatter {
          title
          slug
          date
          description
          locale
        }
      }
    }
  }
`
